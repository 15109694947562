import { State, StateContext, Action, Selector, NgxsOnInit, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { AwareHttpService } from '@appbolaget/aware-http';
import { tap } from 'rxjs/operators';
import { orderBy } from 'natural-orderby';

import * as moment from 'moment';

import { StateKey } from '@helpers';
import { CheckIfLoggedToday, SaveFeedback, GetDiary, SortDiary } from './diary.actions';
import { AuthState } from './auth.state';
import { Feedback } from '../models';
import { NetworkState } from './network.state';
import { SaveRequestToCache } from './network.actions';
import { AppState } from './app.state';
import { Capacitor } from '@capacitor/core';

export interface FeedbackYearInterface {
    year: number;
    months: FeedbackMonthInterface[];
}

export interface FeedbackMonthInterface {
    month: number;
    year: number;
    data: Feedback[];
}

export interface DiaryStateModel {
    feedback: Feedback[];
    dateSortedFeedback: FeedbackYearInterface[];
    hasLoggedToday: boolean;
}

const defaults = {
    feedback: null,
    dateSortedFeedback: null,
    hasLoggedToday: true,
};

@State<DiaryStateModel>({
    name: StateKey.Diary,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class DiaryState implements NgxsOnInit {
    @Selector()
    static feedback({ feedback }: DiaryStateModel): Feedback[] {
        return feedback;
    }

    @Selector()
    static dateSortedFeedback({ dateSortedFeedback }: DiaryStateModel): FeedbackYearInterface[] {
        return dateSortedFeedback;
    }

    @Selector()
    static hasLoggedToday({ hasLoggedToday }: DiaryStateModel): boolean {
        return hasLoggedToday;
    }

    constructor(
        private api: AwareHttpService,
        private store: Store,
    ) {}

    ngxsOnInit({ dispatch, patchState }: StateContext<DiaryStateModel>): void {}

    @Action(SaveFeedback)
    SaveFeedback({ dispatch, getState, patchState }: StateContext<DiaryStateModel>, { feedback }: SaveFeedback) {
        const { connected } = this.store.selectSnapshot(NetworkState);
        const { client } = this.store.selectSnapshot(AuthState);

        const batch: any = {
            feedback: {
                route: `clients/${client.uuid}/feedback`,
                method: 'POST',
                data: feedback,
            },
        };

        if (feedback.uuid) {
            batch.feedback.route = `clients/${client.uuid}/feedback/${feedback.uuid}`;
            batch.feedback.method = 'PUT';
        }

        const created_at = moment().format('YYYY-MM-DD HH:mm:ss');

        if (!connected && !feedback.uuid) {
            batch.feedback.requests = [
                {
                    route: `clients/${client.uuid}/feedback/{{feedback.uuid}}`,
                    method: 'PUT',
                    data: {
                        created_at,
                    },
                },
            ];
        }

        const request = this.api.post('batch', batch);

        if (connected) {
            return request.execute().pipe(tap(() => dispatch(new GetDiary())));
        } else if (Capacitor.isNativePlatform()) {
            const currentFeedback = getState().feedback;

            if (feedback.uuid) {
                const found = currentFeedback.find((f) => f.uuid === feedback.uuid);
                const newFeedback = new Feedback({ ...feedback, created_at: found.created_at });
                const filtered = currentFeedback.filter((f) => f.uuid !== feedback.uuid);

                patchState({ feedback: [...filtered, newFeedback] });
            } else {
                const newFeedback = new Feedback({ ...feedback, created_at });

                patchState({ feedback: [...currentFeedback, newFeedback] });
            }

            dispatch(new SortDiary());

            return this.store.dispatch(new SaveRequestToCache(request));
        }
    }

    @Action(GetDiary)
    getDiary({ patchState, dispatch }: StateContext<DiaryStateModel>) {
        const { client } = this.store.selectSnapshot(AuthState);

        return this.api
            .get(`clients/${client.uuid}/feedback`)
            .toCollection(Feedback)
            .limit(1000)
            .execute()
            .pipe(
                tap((res) => {
                    patchState({ feedback: res.data });
                    dispatch(new SortDiary());
                }),
            );
    }

    @Action(SortDiary)
    sortDiary({ getState, patchState, dispatch }: StateContext<DiaryStateModel>) {
        const data = getState().feedback;

        let years: FeedbackYearInterface[] = [];

        for (const feedback of data) {
            const month = moment(feedback.created_at).month();
            const year = moment(feedback.created_at).year();
            let foundYear = years.find((y) => y.year === year);

            // If no year is found create one.
            if (!foundYear) {
                const data = { year, months: [] };
                years.push(data);
                foundYear = data;
            }

            // Check if the found year has the feedbacks month in it.
            const foundMonth = foundYear.months.find((m) => m.month === month);

            if (!foundMonth) {
                // If the month isn't found in the found year create it.
                foundYear.months.push({
                    month,
                    year,
                    data: [feedback],
                });
            } else {
                // Push the feedback to the found month.
                foundMonth.data.push(feedback);

                // if (month === 3) {
                //     while (foundMonth.data.length < 60) {
                //         // Generate feedback with score between 1-5
                //         foundMonth.data.push(
                //             new Feedback({
                //                 score: Math.floor(Math.random() * 5) + 1,
                //                 created_at: moment()
                //                     .subtract(Math.floor(Math.random() * 60), 'days')
                //                     .format('YYYY-MM-DD HH:mm:ss'),
                //             }),
                //         );
                //     }
                // }
            }
        }

        // Sort years.
        years = orderBy(years, 'year', 'desc');

        // Sort the months of each year.
        for (const year of years) {
            year.months = orderBy(year.months, 'month', 'desc');

            // Sort the feedback in each month.
            for (const month of year.months) {
                month.data = orderBy(month.data, 'created_at', 'desc');
            }
        }

        console.log(years);

        patchState({ dateSortedFeedback: years });
        dispatch(new CheckIfLoggedToday());
    }

    @Action(CheckIfLoggedToday)
    checkIfHasLoggedToday({ patchState, getState }: StateContext<DiaryStateModel>) {
        const { organisation } = this.store.selectSnapshot(AppState);
        const { feedback } = getState();

        if (!feedback?.length) {
            patchState({ hasLoggedToday: false });
        } else {
            const found = feedback.filter((f) => moment(f.created_at).isSame(moment(), 'day'));

            console.log('Log limit: ', organisation.config?.app?.diary?.logLimit);

            patchState({
                hasLoggedToday: found?.length >= organisation.config?.app?.diary?.logLimit || false,
            });
        }
    }
}
