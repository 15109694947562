import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ContactsComponent } from '@components/contacts/contacts.component';
import { ActionSheetController, ModalController, AlertController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';

import { AppState, DeleteContact, SaveContact } from '@state';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Contact } from 'src/app/models';

@Component({
    selector: 'app-contact-editor',
    templateUrl: 'contact-editor.component.html',
    styleUrls: ['contact-editor.component.scss'],
})
export class ContactEditorComponent implements OnInit, OnDestroy {
    form: FormGroup;
    contact: Contact;
    @Select(AppState.isNative) isNative$: Observable<boolean>;

    loader: any;

    ALIVE = true;

    constructor(
        private alertCtrl: AlertController,
        private store: Store,
        private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private actionSheetCtrl: ActionSheetController,
        private appRef: ApplicationRef,
    ) {}

    ngOnInit() {
        this.buildForm();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    close(): void {
        this.modalCtrl.dismiss();
    }

    async openContacts(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: ContactsComponent,
        });

        modal.present();

        const { data } = await modal.onDidDismiss();

        if (data.contact) {
            console.log(data);
            this.form.get('name').patchValue(data.contact.name);
            this.form.get('phoneNumber').patchValue(data.contact.phoneNumber.number);
        }
    }

    async save(): Promise<void> {
        const { name, phoneNumber } = this.form.value;

        FirebaseAnalytics.logEvent({ name: 'save_diary', params: {} });

        this.store.dispatch(new SaveContact(new Contact({ ...this.contact, name, phoneNumber })));

        this.close();
    }

    async showImagePrompt(): Promise<void> {
        (
            await this.actionSheetCtrl.create({
                header: 'Lägg till bild',
                buttons: [
                    {
                        text: 'Bibliotek',
                        icon: 'images',
                        handler: () => this.getImage(CameraSource.Photos),
                    },
                    {
                        text: 'Kamera',
                        icon: 'camera',
                        handler: () => this.getImage(CameraSource.Camera),
                    },
                    {
                        text: 'Stäng',
                        icon: 'close',
                    },
                ],
            })
        ).present();
    }

    async deleteContact() {
        (
            await this.alertCtrl.create({
                header: 'Bekräfta',
                message: `Vill du verkligen ta bort ${this.contact.name}?`,
                buttons: [
                    {
                        text: 'Ta bort',
                        handler: () => {
                            this.store.dispatch(new DeleteContact(this.contact));
                            this.close();
                        },
                    },
                    'Avbryt',
                ],
            })
        ).present();
    }

    clearImage(event) {
        event.preventDefault();

        this.contact = new Contact({ ...this.contact, image: null });

        this.appRef.tick();
    }

    private async getImage(source: CameraSource) {
        const { base64String, format } = await Camera.getPhoto({
            source,
            quality: 100,
            resultType: CameraResultType.Base64,
        });

        this.contact = new Contact({
            ...this.contact,
            image: `data:image/${format};base64,${base64String}`,
        });

        this.appRef.tick();
    }

    private buildForm(): void {
        this.form = this.formBuilder.group({
            name: [this.contact?.name || '', Validators.required],
            phoneNumber: [this.contact?.phoneNumber || '', Validators.required],
        });
    }
}
